var config = {
  demo: false,

  //SEO Configurations
  appName: "Quiz Mania",
  metaDescription: "Quiz Mania è un'applicazione di quiz sul web",
  metaKeyWords: "Quiz Mania,Quiz, domande, risposte, quiz online",
  //API Configurations
  apiAccessKey: 8525,
  apiUrl: "https://quizmania.studionexus.eu/cpanel/api/",

  //Language Configurations
  // Get Your Language Codes ---> https://developers.google.com/admin-sdk/directory/v1/languages
  supportedLanguages: ["it", "en", "hi", "ur"],
  defaultLanguage: "it",

  // If your Default Language is not in supportedLanguages then add there first and after that set the defaultLanguage.

  //Quiz Configurations
  questionTimerSeconds: 15,
  guesstheTimerSeconds: 30,
  levelWinCheckPoint: 30, // Above 30% is required to Clear the Quiz Level
  maxWinningCoins: 4, // This will give user the coins if user will clear the level
  deductReviewAnswerCoins: 10, // 10 coins will be deducted if user Review the Answer
  addCorrectAnswerScore: 2, // This will increase the user score after each correct answer
  deductIncorrectAnswerScore: 1, // This will deduct the points if user will give wrong answer
  deductLifeLineCoins: 1, // Deduct Coins when using Life Line
  DefaultCountrySelectedInMobile: "it", //Default Country Selected in Mobile Login Screen
  Guessthewordhintcoin: 5,

  //Firebase Configurations
  apiKey: "AIzaSyD4-5g9T9xG59L5b7qI6W_mOVzldTqsaEc",
  authDomain: "quiz-mania-italia.firebaseapp.com",
  projectId: "quiz-mania-italia",
  storageBucket: "quiz-mania-italia.appspot.com",
  messagingSenderId: "415970365967",
  appId: "1:415970365967:web:d0c55c096daa372efd097a",
  measurementId: "G-TRSD4EPSY6",

  //footer area
  companytext:"Quiz Mania è stato realizzato con i principi chiave di un codice bello, efficace, semplice da usare e di migliore qualità con l'uso di componenti funzionali.",
  addresstext:"Indirizzo: Via Dottor G. Beffa Albenga (SV) 17031 Italy.",
  phonenumber: "+39 3896989757",
  email: "helpdesk@studionexus.it",
  facebooklink: "https://www.facebook.com/studionexusingauna/",
  instagramlink: "https://www.instagram.com/studionexus/",
  linkedinlink: "https://www.linkedin.com/in/studio-nexus-943987136?trk=hp-identity-name",
  weblink: "https://studionexus.it/",
  companyname: "STUDIO NEXUS.",
};

export default config;
